<template>
  <div class="weekly-todo">
    <div class="header">
      <div class="tight">
        <button class="btn" :class="{active: !($route.query.team)}" :disabled="!state.member.loaded" @click="setTabs('team')">
          <span>ALL</span>
        </button>
        <button class="btn" :class="{active: $route.query.team === t.name}" v-for="(t, idx) in teams" :key="idx" :disabled="!state.member.loaded" @click="setTabs('team', t.name)">
          <span class="team" :class="t.name" :style="$route.query.team === t.name && {background: t.color}">
            <span class="dot" :style="{background: t.color}"></span>
            <span>{{ t.title }}</span>
          </span>
        </button>
      </div>
    </div>
    <div class="tabs">
      <div class="members">
        <div class="tight thin-scrollbar" v-if="state.args.team === 'all' || !state.args.team || !state.member.loaded">
          <button class="btn" :disabled="state.todos.every(t=> !t.loaded)" :class="{active: !($route.query.memberSeq), skeleton: !state.member.loaded}" @click="setTabs('member')">
            <span>
              <span>ALL</span>
              <span class="badge">{{ getCount() }}</span>
            </span>
          </button>
          <button class="btn" :disabled="state.todos.every(t=> !t.loaded)" v-for="(m, idx) in state.member.list" :key="idx"
                  :class="{active: $route.query.memberSeq === m.memberSeq, skeleton: !state.member.loaded}" @click="setTabs('member', m.memberSeq)">
            <span>
              <span>{{ m.memberName }}</span>
              <span class="badge member-count">{{ getCount(m.memberSeq) }}</span>
            </span>
          </button>
        </div>
        <div class="tight" v-else>
          <button class="btn" :class="{active: !($route.query.memberSeq)}" @click="setTabs('member')" :disabled="state.todos.some(t=> !t.loaded)">
            <span>
              <span>ALL</span>
              <span class="badge">{{ getCount() }}</span>
            </span>
          </button>
          <button class="btn" v-for="(m, idx) in state.member.list" :key="idx" :class="{active: $route.query.memberSeq === m.memberSeq}" @click="setTabs('member', m.memberSeq)" :disabled="state.todos.some(t=> !t.loaded)">
            <span>
              <span>{{ m.memberName }}</span>
              <span class="badge member-count">{{ getCount(m.memberSeq) }}</span>
            </span>
          </button>
        </div>
      </div>
      <select class="form-control" @change="onChangeItemColCnt()" v-model="state.itemColCnt" :disabled="$route.query.team ? state.todos.some(t=> !t.loaded) : state.todos.every(t=> !t.loaded)">
        <option :value="n" v-for="n in itemColCounts" :key="n">{{ n }}개씩 보기</option>
      </select>
    </div>
    <AdminWeeklyTodos :todos="state.todos" :updateState="updateState" :loadMore="loadMore" :itemColCnt="state.itemColCnt"
                      :updateMembersCount="updateMembersCount" :teams="teams" :members="state.member.list" :loaded="state.member.loaded"
                      v-if="state.todos.some(t => !t.loaded) || state.todos.some(t => Number(t.totalCount) !== 0)"/>
    <NoData class="pt-5" v-else/>
    <div class="add" @click="add()" v-if="addShow()">
      <i class="fa fa-plus"></i>
    </div>
  </div>
</template>

<script>
import {defineComponent, nextTick, reactive, watch} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import AdminWeeklyTodos from "@/components/AdminWeeklyTodos";
import router from "@/scripts/router";
import lib from "@/scripts/lib";
import http from "@/scripts/http";
import store from "@/scripts/store";
import NoData from "@/components/NoData";
import teams from "@/texts/admin/teams.json";
import storage from "@/scripts/storage";

function Component(initialize) {
  this.name = "pageAdminWeeklyTodo";
  this.initialize = initialize;
}

export default defineComponent({
  components: {NoData, AdminWeeklyTodos},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      const colCnt = Number(storage.get("local", "adminWeeklyTodoItemColCnt"));

      if (colCnt && !itemColCounts.includes(colCnt)) {
        storage.remove("local", "adminWeeklyTodoItemColCnt");
      }

      load();
      setMembers();
    });

    const state = reactive({
      allCount: 0,
      itemColCnt: 4,
      teams: teams,
      member: {
        loaded: false,
        list: []
      },
      args: {
        pageIndex: 1,
        pageSize: 3,
        pageUnit: 8,
        category: "",
        finished: false,
        team: "",
        memberSeq: "",
      },
      todos: [{
        title: "금주 계획",
        section: "current",
        items: [],
        totalCount: 0,
        pageIndex: 1,
        loaded: false,
      }, {
        title: "단기 계획",
        section: "shortTerm",
        items: [],
        totalCount: 0,
        pageIndex: 1,
        loaded: false,
      }, {
        title: "장기 계획",
        section: "longTerm",
        items: [],
        totalCount: 0,
        pageIndex: 1,
        loaded: false,
      }, {
        title: "처리 완료",
        section: "finished",
        items: [],
        totalCount: 0,
        pageIndex: 1,
        loaded: false,
      }, {
        title: "보류",
        section: "hold",
        items: [],
        totalCount: 0,
        pageIndex: 1,
        loaded: false,
      }],
    });

    const itemColCounts = [4, 3, 2];

    const add = () => {
      store.commit("openModal", {
        name: "Todo",
        params: {
          type: "add",
          members: state.member.list.filter(m => m.omcTeam === store.state.account.omcTeam),
        },
        callback: `${component.name}.onModalClose`
      });
    };

    const addShow = () => {
      if (!router.app.$route.query.team) {
        if (router.app.$route.query.memberSeq) {
          return router.app.$route.query.memberSeq === store.state.account.memberSeq;
        }

        return true;
      } else {
        if (!router.app.$route.query.memberSeq) {
          return state.member.list.some(m => m.memberSeq === store.state.account.memberSeq && m.omcTeam === router.app.$route.query.team);
        }

        return router.app.$route.query.memberSeq === store.state.account.memberSeq;
      }
    };

    const onChangeItemColCnt = () => {
      switch (state.itemColCnt) {
        case 4:
          storage.remove("local", "adminWeeklyTodoItemColCnt");
          break;

        default:
          storage.set("local", "adminWeeklyTodoItemColCnt", state.itemColCnt.toString());
          break;
      }

      state.args.pageUnit = getPageUnit(state.itemColCnt);
      load();
    };

    const getCount = (memberSeq) => {
      let result = 0;

      for (let m of state.member.list) {
        if (m.memberSeq === memberSeq) {
          return m.current;
        }

        result += m.current;
      }

      return result;
    };

    const setMembers = () => {
      if (!state.args.team) {
        state.args.team = "all";
      }

      state.member.list = [{
        memberName: "Wait",
        skeleton: true
      }, {
        memberName: "Wait",
        skeleton: true
      }, {
        memberName: "Wait",
        skeleton: true
      }];

      state.member.loaded = false;
      http.get(`/api/admin/todos/teams/${state.args.team}/members`).then(({data}) => {
        state.member.loaded = true;
        state.member.list = data.body;
      });
    };

    const updateMembersCount = (prop, type) => {
      const member = state.member.list.find(m => m.memberSeq === prop.memberSeq);

      switch (type) {
        case "delete": {
          member[prop.finished ? "finished" : prop.category] -= 1;
          break;
        }
        case "toggle": {
          member[prop.finished ? "finished" : prop.category] -= 1;
          member[!prop.finished ? "finished" : prop.category] += 1;

          break;
        }

        case "drag": {
          const dragMember = state.member.list.find(m => {
            return m.memberSeq === prop.item.dataset.memberSeq;
          });

          if (prop.to.dataset.section === "finished") {
            dragMember.finished += 1;
            dragMember[prop.from.dataset.section] -= 1;
          } else if (prop.from.dataset.section === "finished") {
            dragMember.finished -= 1;
            dragMember[prop.to.dataset.section] += 1;
          }
          break;
        }
      }

    };

    const setTabs = (tab, name) => {
      const query = lib.getRenewed(router.app.$route.query);

      for (let i in state.todos) {
        state.todos[i].pageIndex = 1;
      }

      switch (tab) {
        case "team": {
          state.args.team = name;
          if (!state.args.team) {
            delete query.team;
          }

          query.team = state.args.team;
          delete query.memberSeq;
          state.args.memberSeq = "";
          break;
        }

        case "member": {
          state.args.memberSeq = name;
          query.memberSeq = state.args.memberSeq;
          break;
        }
      }

      pushToRouter(query);
    };

    const pushToRouter = (query) => {
      if (JSON.stringify(router.app.$route.query) === JSON.stringify(query)) {
        return;
      }

      router.push({query});
    };

    const loadMore = (section, pageIndex) => {
      resetState();

      const todo = state.todos.find(t => t.section === section);

      if (!pageIndex) {
        todo.pageIndex += 1;

        for (let j = 0; j < state.args.pageUnit; j += 1) {
          todo.items.push({
            title: "Wait a moment",
            content: "Please wait a moment",
            createDate: "0000-00-00 00:00:00",
            memberName: "Wait",
            omcTeam: "Wait",
            skeleton: true
          });
        }
      }

      state.args.pageIndex = todo.pageIndex;

      if (section === "finished") {
        state.args.finished = true;
      } else {
        state.args.category = section;
        state.args.finished = false;
      }

      const set = (args) => {
        http.get("/api/admin/todos", args).then(({data}) => {
          if (data.body.list.length === 0) {
            return store.commit("setSwingMessage", "불러올 아이템이 없습니다.");
          }

          let todos = state.todos.find(t => t.section === section);

          todos.items = todos.items.filter(i => {
            return !i.skeleton;
          });

          for (let i in data.body.list) {
            !todos.items.some(item => item.todoSeq === data.body.list[i].todoSeq)
            && todos.items.push(data.body.list[i]);
          }
        });
      };

      set(state.args);
    };

    const updateState = (newItems, callback) => {
      for (let i in newItems) {
        const newItem = newItems[i];
        let stateTodo, stateItem;

        loop: for (let i1 in state.todos) {
          for (let i2 in state.todos[i1].items) {
            if (state.todos[i1].items[i2].todoSeq === newItem.todoSeq) {
              stateTodo = state.todos[i1];
              stateItem = state.todos[i1].items[i2];
              break loop;
            }
          }
        }

        stateItem.ord = newItem.ord;
        stateItem.finished = newItem.finished;

        let newStateTodo;

        if (newItem.finished) {
          if (stateTodo.section !== "finished") {
            newStateTodo = state.todos.find(t => t.section === "finished");
          }
        } else if (stateTodo.section !== newItem.category) {
          newStateTodo = state.todos.find(t => t.section === newItem.category);
        }

        if (!newStateTodo) {
          continue;
        }

        newStateTodo.items.push(stateItem);
        newStateTodo.totalCount += 1;

        stateTodo.items.splice(stateTodo.items.indexOf(stateItem), 1);
        stateTodo.totalCount -= 1;

        stateTodo.totalCount > stateTodo.items.length
        && loadMore(stateTodo.section, stateTodo.pageIndex);
      }

      for (let i in state.todos) {
        state.todos[i].items.sort((a, b) => {
          return a.ord - b.ord;
        });
      }

      const todos = lib.getRenewed(state.todos);
      state.todos = [];

      nextTick(() => {
        state.todos = todos;
        callback();
      });
    };

    const getPageUnit = (cnt) => {
      switch (cnt) {
        case 3:
          return 9;

        case 4:
          return 8;
      }

      return 10;
    };

    const resetState = (type) => {
      const query = router.app.$route.query;

      state.args.category = "";
      state.args.finished = false;

      if (!query.team) {
        state.args.team = "";
      } else {
        state.args.team = query.team;
      }

      if (!query.memberSeq) {
        state.args.memberSeq = "";
      } else {
        state.args.memberSeq = query.memberSeq;
      }

      if (type === "load") {
        const col = Number(storage.get("local", "adminWeeklyTodoItemColCnt")) || 4;

        for (let i in state.todos) {
          state.todos[i].pageIndex = 1;
          state.todos[i].items = [];
        }
        if (!col) {
          return state.itemColCnt = 4;
        }

        state.itemColCnt = col;
        state.args.pageIndex = 1;
        state.args.pageUnit = getPageUnit(col);
      }
    };

    const scrollToTop = () => {
      window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    };

    const onModalClose = () => {
      load();
      setMembers();
    };

    const load = () => {
      resetState("load");

      const set = (args) => {
        const todo = state.todos.find(t => t.section === (args.finished ? "finished" : args.category));

        for (let i in state.todos) {
          state.todos[i].items = [];
          for (let j = 0; j < state.args.pageUnit; j += 1) {
            state.todos[i].items.push({
              title: "Wait a moment",
              content: "Please wait a moment",
              createDate: "0000-00-00 00:00:00",
              memberName: "Wait",
              omcTeam: "Wait",
            });
          }
        }

        return new Promise((resolve, reject) => {
          todo.loaded = false;

          http.get("/api/admin/todos", args).then(({data}) => {
            if ((!args.memberSeq && router.app.$route.query.memberSeq) || (!router.app.$route.query.memberSeq && args.memberSeq)) {
              return;
            }

            if ((!args.team && router.app.$route.query.team) || (!router.app.$route.query.team && args.team)) {
              return;
            }

            todo.loaded = true;

            for (let i in data.body.list) {
              const item = data.body.list[i];
              item.order = Number(i);
            }

            todo.items = data.body.list;
            todo.totalCount = data.body.paginationInfo.totalRecordCount;
            resolve();
          }).catch(() => {
            reject();
          });
        });
      };

      const promises = [
        set({...state.args, finished: true}),
        set({...state.args, category: "current"}),
        set({...state.args, category: "shortTerm"}),
        set({...state.args, category: "longTerm"}),
        set({...state.args, category: "hold"})
      ];

      Promise.all(promises).then(() => {
      });
    };

    watch(() => router.app.$route.query.team, () => {
      load();
      setMembers();
      scrollToTop();
    });

    watch(() => router.app.$route.query.memberSeq, () => {
      load();
      scrollToTop();
    });

    return {component, state, teams, itemColCounts, add, onChangeItemColCnt, setTabs, updateState, onModalClose, loadMore, getCount, updateMembersCount, addShow};
  }
});
</script>

<style lang="scss" scoped>
.weekly-todo {
  padding-bottom: $px20;

  .header {
    position: absolute;
    right: $px40;
    top: $px27;

    > .tight {
      > button {
        display: inline-block;
        padding-left: $px8;

        > span {
          font-size: $px12;
          padding: $px5 $px10;
          border-radius: $px15;
          position: relative;

          &.team {
            .dot {
              content: "";
              display: inline-block;
              position: absolute;
              top: 0;
              left: 50%;
              transform: translateX(-50%);
              width: $px3;
              height: $px3;
              border-radius: 50%;
            }
          }

          &:hover {
            color: #000;
          }
        }

        &.active > span {
          background: $colorPurple;
          color: #fff;
        }

        &.btn {
          padding: 0;
          margin: 0;
        }
      }
    }
  }

  .tabs {
    position: relative;
    padding-right: $px150;
    margin-top: $px5;
    margin-bottom: $px25;
    height: $px42;

    .members {
      padding-top: $px5;

      > .tight {
        white-space: nowrap;
        overflow: auto;
        position: relative;

        > button {
          display: inline-block;
          margin-right: $px15;
          border: none;

          > span {
            display: inline-block;
            font-size: $px14;
            min-width: $px70;
            padding: 0 $px3 $px8 $px3;
            text-align: center;
            border-bottom: $px2 solid transparent;
            transition: border-color 0.25s, color 0.25s;

            span {
              vertical-align: middle;

              &.badge {
                margin-left: $px6;
                background: $colorBackground;
                border-radius: $px10;
              }
            }

            &:hover {
              color: #000;
            }
          }

          &.skeleton {
            > span {
              border-radius: $px6;
              @include skeleton;

              .badge {
                display: none;
              }
            }
          }

          &.active {
            > span {
              color: $colorPurple;
              border-color: $colorPurple;
            }
          }

          &.btn {
            padding: 0;
          }
        }

        &::-webkit-scrollbar {
          height: $px3;
        }
      }
    }

    > select {
      position: absolute;
      top: 0;
      right: 0;
      width: $px120;
      height: 100%;
    }
  }

  .add {
    background: $colorPurple;
    bottom: $px30;
    border-radius: 50%;
    height: $px60;
    padding: 0;
    left: 100%;
    width: $px60;
    cursor: pointer;
    text-align: center;
    position: sticky;
    transition: background-color 0.18s;
    z-index: 110;

    .fa-plus {
      color: #fff;
      font-size: $px22;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      background: $colorPurpleActive;
    }
  }

  @media (max-width: 991px) {
    .tabs {
      padding-right: 0;

      .members {
        > .tight {
          overflow: auto;
        }
      }

      > select {
        display: none;
      }
    }
  }

  @media (max-width: 767px) {
    .header {
      display: none;
    }

    .tabs .members > .tight {
      text-overflow: clip;

      > li {
        padding-right: $px8;

        > span {
          font-size: $px11;
          min-width: $px50;
        }
      }
    }

    .add {
      width: $px50;
      height: $px50;
      margin-left: $px-10;

      .fa-plus {
        font-size: $px18;
      }
    }
  }
}
</style>