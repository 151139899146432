<template>
  <div class="admin-weekly-todos">
    <div class="list" :class="{skeleton: !t.loaded || !loaded}" v-for="(t, idx1) in todos" :key="idx1" v-show="!t.loaded ? true : Number(t.totalCount) !== 0">
      <div class="header">
        <div class="title">
          <span>{{ t.title }}</span>
          <span class="badge">{{ t.totalCount }}</span>
        </div>
      </div>
      <div class="items row" :data-section="t.section" ref="itemsRef">
        <div class="item col-12 col-md-6" :data-finished="item.finished === false ? 0 : 1" :data-ord="item.ord" :data-seq="item.todoSeq" :data-section="t.section"
             :data-item-cnt="itemColCnt" :data-category="item.category" :data-member-seq="item.memberSeq"
             v-for="(item, idx2) in t.items" :key="idx2 + '' + item.ord" :class="{
                 skeleton: !t.loaded || item.skeleton, more: t.totalCount > t.items.length && idx2 === t.items.length - 1,
                 my: item.memberSeq === $store.state.account.memberSeq && !isMore(t, item, idx2),
                 'col-lg-3' : itemColCnt === 4, 'col-lg-4' : itemColCnt === 3, 'col-lg-12' : itemColCnt === 1,
               }">
          <div class="inner" :class="{identify: $store.state.account.memberSeq !== item.memberSeq, more: t.totalCount > t.items.length && idx2 === t.items.length - 1}">
            <div class="wrapper" @click="open(t, item)">
              <div class="top">
                <div class="left">
                  <div class="member-name">
                    <MemberPicture :memberSeq="item.memberSeq" size="20"/>
                    <span class="value">{{ item.memberName }}</span>
                  </div>
                  <div class="team badge" :class="item.omcTeam" v-if="!$route.query.team && !$route.query.memberSeq" :style="{background: getTeamProp(item, 'color')}">
                    <span>{{ getTeamProp(item, "title") }}</span>
                  </div>
                </div>
                <button type="button" title="완료 여부" class="btn btn-primary check" :class="{checked: t.section === 'finished'}" @click.stop="toggle(t, item)"
                        v-show="t.section !== 'hold' && $store.state.account.memberSeq === item.memberSeq">
                  <i class="fa fa-check"></i>
                </button>
              </div>
              <div class="content">
                <div class="title">
                  <span>{{ item.title }}</span>
                </div>
                <p class="text">
                  <span>{{ item.content }}</span>
                </p>
              </div>
              <div class="bottom clearfix">
                <span class="float-left date" :title="`생성일(${item.createDate})`">{{ $lib.getHumanReadableDate(item.createDate) }}</span>
                <i class="float-right fa fa-trash pointer" @click.stop="remove(item)" v-if="$store.state.account.memberSeq === item.memberSeq" title="삭제하기"></i>
              </div>
            </div>
            <div class="more" @click="loadMore(t.section)" v-if="isMore(t, item, idx2)">
              <div>더 보기&nbsp; +</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, nextTick, onMounted, reactive, ref} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import http from "@/scripts/http";
import Sortable from "sortablejs";
import NoData from "@/components/NoData";
import MemberPicture from "@/components/MemberPicture";
import definitions from "@/scripts/definitions";

function Component(initialize) {
  this.name = "pagesAdminWeeklyTodos";
  this.initialize = initialize;
}

export default defineComponent({
  components: {MemberPicture, NoData},
  mixins: [mixin],
  props: {
    itemColCnt: Number,
    loaded: Boolean,
    todos: Array,
    teams: Array,
    members: Array,
    loadMore: Function,
    updateMembersCount: Function,
    updateState: Function,
  },
  setup(props) {
    const component = new Component(() => {
    });

    const state = reactive({});
    const itemsRef = ref();

    const getTeamProp = (t2, key) => {
      if (!key || !t2.omcTeam) {
        return;
      } else if (t2.omcTeam === "Wait") {
        return "Wait";
      }

      const team = props.teams.find(t => t.name === t2.omcTeam);

      if (!team) {
        return;
      }

      return team[key];
    };

    const setSortable = () => {
      const $itemsList = itemsRef.value;

      const onEnd = (e) => {
        const args = {
          todos: []
        };

        props.updateMembersCount(e, "drag");

        for (let i = 0; i < itemsRef.value.length; i += 1) {
          const $items = itemsRef.value[i].querySelectorAll(".item[data-seq]");
          const section = itemsRef.value[i].dataset.section;

          for (let idx = 0; idx < $items.length; idx += 1) {
            if (idx !== window.Number($items[idx].dataset.ord) || section !== $items[idx].dataset.section) {
              const category = $items[idx].dataset.category;

              args.todos.push({
                todoSeq: window.Number($items[idx].dataset.seq),
                category: section === "finished" ? category : section,
                ord: idx,
                finished: section === "finished"
              });

              if (e.to.dataset.section === "finished") {
                e.item.querySelector(".check").classList.add("checked");
              } else {
                e.item.querySelector(".check").classList.remove("checked");
              }
            }
          }
        }

        if (args.todos.length === 0) {
          return;
        }

        http.put("/api/admin/todos/order", args.todos).then(() => {
          props.updateState(args.todos, () => {
            nextTick(setSortable);
          });
        });
      };

      const onMove = (e) => {
        return e.related.className.indexOf("more") === -1;
      };

      const onChange = (e) => {
        if (e.to === e.from) {
          for (let i in itemsRef.value) {
            itemsRef.value[i].classList.remove("chosen");
          }
        } else {
          e.to.classList.add("chosen");
        }
      };

      for (let i = 0; i < $itemsList.length; i += 1) {
        Sortable.create($itemsList[i], {
          handel: ".item",
          filter: ".empty, .identify, .skeleton, .more",
          group: "nested",
          onEnd: onEnd,
          onMove: onMove,
          onChange: onChange,
          animation: 350,
          forceFallback: true,
          fallbackTolerance: 5,
        });
      }
    };

    const isMore = (todo, item, idx2) => {
      return todo.loaded && !item.skeleton && todo.items.length < todo.totalCount && idx2 === todo.items.length - 1;
    };

    const open = (todo, item) => {
      if (!todo.loaded || !props.loaded) {
        return;
      }

      const members = props.members.filter(m => m.omcTeam === item.omcTeam);

      if (!members.length) {
        return store.commit("setSwingMessage", definitions.messages.errorCommon);
      }

      store.commit("openModal", {
        name: "Todo",
        params: {
          type: "update",
          todoSeq: item.todoSeq,
          members,
        },
        callback: "pageAdminWeeklyTodo.onModalClose"
      });
    };

    const remove = (todo) => {
      const section = props.todos.find(t => {
        return todo.finished ? t.section === "finished" : t.section === todo.category;
      });

      store.commit("confirm", {
        message: "삭제하시겠습니까?",
        allow() {
          http.delete(`/api/admin/todos/${todo.todoSeq}`).then(() => {
            store.commit("setSwingMessage", "삭제하였습니다.");
            props.updateMembersCount(todo, "delete");
            section.totalCount -= 1;
            section.items.splice(section.items.indexOf(todo), 1);
            section.totalCount > section.items.length && props.loadMore(section.section, section.pageIndex);
          });
        }
      });
    };

    const toggle = (todo, item) => {
      const getMinOrd = () => {
        let result = 0;

        for (let i1 in props.todos) {
          for (let i2 in props.todos[i1].items) {
            if (props.todos[i1].items[i2].ord < result) {
              result = props.todos[i1].items[i2].ord;
            }
          }
        }

        return result;
      };

      props.updateMembersCount(todo.items.find(i => i.todoSeq === item.todoSeq), "toggle");

      const args = {
        finished: !item.finished,
        ord: getMinOrd() - 1,
        todoSeq: item.todoSeq,
      };

      const currentTodo = props.todos.find(t => t.section === "current");

      if (currentTodo.totalCount !== 0 && item.finished) {
        props.loadMore("current", currentTodo.pageIndex);
      } else if (todo.totalCount > todo.items.length) {
        props.loadMore(todo.section, todo.pageIndex);
      }

      if (item.finished) {
        args.category = "current";
      }

      http.put(`/api/admin/todos/${item.todoSeq}`, args).then(() => {
        store.commit("setSwingMessage", item.finished ? "금주 계획 카테고리로 이동하였습니다." : "처리 완료 카테고리로 이동하였습니다.");
        todo.section === "finished" && http.put("/api/admin/todos/order", [args]);
        props.updateState([args], () => {
          nextTick(setSortable);
        });
      });
    };

    onMounted(() => {
      setSortable();
    });

    return {component, state, itemsRef, open, toggle, remove, getTeamProp, isMore};
  }
});
</script>

<style lang="scss" scoped>
.admin-weekly-todos {
  .list {
    padding-top: $px5;
    padding-bottom: $px15;
    position: relative;

    .header {
      padding-bottom: $px15;

      .title {
        font-size: $px17;
        padding-left: $px3;
        font-weight: 500;

        .badge {
          padding: $px4 $px10;
          background: #f8f9fa;
          border: $px1 solid #f7f7f7;
          margin-left: $px5;
          vertical-align: middle;
          text-transform: none;
          position: relative;
          top: $px-1;
        }
      }

      .btn {
        font-size: $px20;
        width: $px35;
        height: $px35;
        padding: 0;
        border-radius: 50%;
        font-weight: 700;
      }
    }

    .items {
      margin: 0 $px-10;

      .empty {
        display: table;
        height: $px330;

        .wrapper {
          background: #f3f3f3;
          border-radius: $px6;
          margin: 0;
          height: 100%;
          position: relative;
          text-align: center;
          display: table-cell;
          vertical-align: middle;
          padding: 0;
        }
      }

      .notice {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        display: none;
      }

      .item {
        padding-bottom: $px20;
        padding-left: $px10;
        padding-right: $px10;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        .inner {
          background: #fff;
          border-radius: $px6;
          border: $px1 solid $colorBorder;
          cursor: pointer;
          height: $px163;
          padding: $px13 $px15;
          position: relative;
          transition: border 0.25s;

          > .wrapper {
            .top {
              position: relative;
              display: flex;
              align-items: flex-end;
              justify-content: space-between;

              .left {
                .member-name {
                  display: inline-block;

                  span {
                    vertical-align: middle;

                    &.img {
                      margin-top: $px-3;
                    }

                    &.value {
                      font-size: $px12;
                      font-weight: 300;
                      line-height: 1;
                      display: inline-block;
                      padding-right: $px2;
                      margin-left: $px5;
                    }
                  }
                }

                .badge {
                  border-radius: $px6;
                  color: #fff;
                  font-size: $px10;
                  margin-left: $px3;
                  padding: $px4 $px6;
                }
              }

              > .btn {
                border-radius: $px14;
                font-size: $px11;
                padding: $px2 $px12;

                &.check {
                  border-radius: $px4;
                  background: #fff;
                  border-color: #eee;
                  width: $px24;
                  height: $px24;
                  padding: 0;

                  &:hover {
                    transition: none;
                    border: $px1 solid #a7b1f1;

                    i {
                      opacity: 0.3;
                    }
                  }

                  i {
                    color: #000;
                    font-size: $px12;
                    opacity: 0.15;
                  }
                }

                &.checked {
                  background: $colorPurple;

                  i {
                    color: #fff;
                    opacity: 1;
                  }
                }
              }
            }

            .title {
              font-size: $px14;
              color: #3c3c3c;
              margin-top: $px6;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-weight: 500;
              margin-bottom: $px5;
            }

            .text {
              color: #666;
              height: $px42;
              font-size: $px13;
              font-weight: 400;
              margin-bottom: $px18;
              white-space: pre-line;
              display: -webkit-box;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            .bottom {
              .date {
                font-weight: 200;
                font-size: $px12;
              }

              > i {
                display: none;
                color: #a6a6a9;
                padding: $px5;
                margin: $px-5;

                &:hover {
                  color: #3c3c3c;
                }
              }
            }
          }

          .more {
            background: #fff;
            border-radius: $px6;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: background 0.18s;

            > div {
              position: absolute;
              top: 50%;
              left: 50%;
              font-size: $px14;
              transform: translate(-50%, -50%);

              > span {
                vertical-align: middle;
              }

              .arrow-icon {
                font-size: $px16;
                padding-left: $px10;
              }
            }

            &:hover {
              background: $colorBackground;
            }
          }

          &:hover:not(.more) {
            border: $px1 solid $colorPurple;

            > .wrapper .bottom > i {
              display: inline-block;
            }
          }
        }

        &.my .inner > .wrapper .top .left .member-name {
          font-weight: 500;
        }

        &[data-item-cnt='2'] {
          padding-bottom: $px15;

          .inner {
            height: auto;

            > .wrapper {
              padding-bottom: $px5;

              .title {
                margin-bottom: 0;
              }

              .text {
                display: none;
              }

              .bottom {
                position: relative;

                .date {
                  font-weight: 200;
                  font-size: $px12;
                  position: absolute;
                  right: 0;
                  bottom: 0;
                }

                > i {
                  display: none !important;
                }
              }
            }
          }
        }

        &.skeleton {
          .inner {
            .top {
              .left {
                .member-name {
                  @include skeleton;

                  .img {
                    visibility: hidden;
                  }
                }

                .badge {
                  @include skeleton;
                }
              }

              > .btn.check {
                @include skeleton;
              }
            }

            .title > span,
            .text {
              @include skeleton;
            }

            .bottom {
              .date {
                @include skeleton;
              }

              > i {
                display: none !important;
              }
            }
          }
        }
      }

      &.chosen {
        .empty {
          display: none;
        }
      }
    }

    &.skeleton {
      .header .title {
        > span {
          @include skeleton;
        }
      }
    }
  }

  .sortable-fallback {
    .inner {
      border: $px1 solid $colorPurple !important;
    }
  }

  @media (max-width: 767px) {
    .list {
      .header {
        .title {
          font-size: $px15;
        }
      }

      .items {
        .empty {
          height: $px265;
        }

        .item {
          .inner {
            height: $px170;

            > .wrapper {
              .text {
                -webkit-line-clamp: 2;
                height: $px44;
                margin-bottom: $px26;
              }
            }
          }
        }
      }
    }
  }
}
</style>